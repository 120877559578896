//TODO document this
//TODO clean up public map dynamic filters, toggles, and controls

export const BASEMAP_STYLES = [
  {
    style: "streets-v12",
    name: "Streets",
    url: "https://wilco-dashboard-files.s3.us-west-1.amazonaws.com/styles/streets-v12/streets-v12.json",
  },
  {
    style: "outdoors-v12",
    name: "Outdoors",
    url: "https://wilco-dashboard-files.s3.us-west-1.amazonaws.com/styles/outdoors-v12/outdoors-v12.json",
  },
  {
    style: "satellite-v9",
    name: "Satellite",
    url: "https://wilco-dashboard-files.s3.us-west-1.amazonaws.com/styles/satellite-v9/satellite-v9.json",
  },
  {
    style: "satellite-streets-v12",
    name: "Satellite Streets",
    url: "https://wilco-dashboard-files.s3.us-west-1.amazonaws.com/styles/satellite-streets-v12/satellite-streets-v12.json",
  },
];

export const BASEMMAP_IMAGE_CENTER = [-97.7431, 30.6];
export const DEFAULT_MAP_CENTER = [-97.7431, 30.6];
export const DEFAULT_MAP_ZOOM = 9.2;
export const DEFAULT_MAP_BASEMAP = BASEMAP_STYLES[0].url;
export const CIRCLE_FALLBACK_COLOR = "#9e9e9e";

export const INIT_MAP_CONFIG = {
  style: DEFAULT_MAP_BASEMAP,
  center: DEFAULT_MAP_CENTER,
  zoom: DEFAULT_MAP_ZOOM,
  preserveDrawingBuffer: true,
  attributionControl: true,
};

export const WELLS_SYMBOL_LAYER_ID = "groundwater-database-symbol";
export const WELLS_LABELS_LAYER_ID = "groundwater-database-label";

// a dynamic color-by must be included in this list as well. if it should be colored but not filtered, just add the excludeFilter boolean
export const INIT_FILTER_VALUES = {
  wellTypes: {
    label: "Well Type",
    name: "wellTypes",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "well_type_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellUses: {
    label: "Well Use",
    name: "wellUses",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "use_group_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  aquiferGroups: {
    label: "Aquifer",
    name: "aquiferGroups",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "aquifer_group_name",
    options: [],
    type: "multi-select",
    value: [],
  },

  hasWaterLevelData: {
    label: "Has WL Data?",
    name: "hasWaterLevelData",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "has_wl",
    type: "boolean",
    value: false,
  },
  hasWaterQualityData: {
    label: "Has WQ Data?",
    name: "hasWaterQualityData",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "has_wq",
    type: "boolean",
    value: false,
  },

  mapGwdbLegend: {},
};

const COMMON_ICON_SIZE = [
  "interpolate",
  ["linear"],
  ["zoom"],
  10,
  0.4,
  11,
  0.5,
  12,
  0.6,
  13,
  0.7,
  14,
  0.8,
  15,
  1,
];

export const INIT_STYLE_VALUES = {
  wellTypes: {
    id: "wellTypes",
    defaultLoad: true,
    layerId: WELLS_SYMBOL_LAYER_ID,
    category: "well_type",
    name: "Well Type",
    layout: {
      "icon-image": ["get", "sprite_ndx_well_type"],
    },
  },
  wellUses: {
    id: "wellUses",
    layerId: WELLS_SYMBOL_LAYER_ID,
    category: "use_group",
    name: "Well Use",
    layout: {
      "icon-image": ["get", "sprite_ndx_use_group"],
    },
  },
  aquiferGroups: {
    id: "aquiferGroups",
    layerId: WELLS_SYMBOL_LAYER_ID,
    category: "aquifer_group",
    name: "Aquifer",
    layout: {
      "icon-image": ["get", "sprite_ndx_aquifer_group"],
    },
  },
  wellElevation: {
    id: "wellElevation",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "elevation_ftabmsl",
    name: "Well Elevation",
    layout: {
      "icon-image": "circle",
      "icon-size": COMMON_ICON_SIZE,
    },
    paint: {
      "icon-color": [
        "interpolate-hcl",
        ["linear"],
        ["coalesce", ["get", "elevation_ftabmsl"], Infinity],
        0,
        "#0d47a1", // Very Low (Dark Blue)
        550,
        "#1565c0", // Low (Medium Dark Blue)
        750,
        "#1e88e5", // Moderate Low (Bright Blue)
        950,
        "#42a5f5", // Moderate (Sky Blue)
        1050,
        "#90caf9", // Moderate High (Light Blue)
        1200,
        "#e3f2fd", // High (Pale Blue)
        Infinity,
        CIRCLE_FALLBACK_COLOR,
      ],
    },
    lreProperties: {
      borderColor: "#000",
      legend: {
        units: "ft",
      },
    },
  },
  wellDepth: {
    id: "wellDepth",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "well_depth_ft",
    name: "Well Depth",
    layout: {
      "icon-image": "circle",
      "icon-size": COMMON_ICON_SIZE,
    },
    paint: {
      "icon-color": [
        "interpolate-hcl",
        ["linear"],
        ["coalesce", ["get", "well_depth_ft"], Infinity],
        0,
        "#0d47a1", // Very Shallow (Dark Blue)
        300,
        "#1565c0", // Shallow (Medium Dark Blue)
        1000,
        "#1e88e5", // Moderate (Bright Blue)
        2000,
        "#42a5f5", // Deep (Sky Blue)
        3500,
        "#90caf9", // Very Deep (Light Blue)
        6000,
        "#e3f2fd", // Extreme Depth (Pale Blue)
        Infinity,
        CIRCLE_FALLBACK_COLOR,
      ],
    },
    lreProperties: {
      borderColor: "#000",
      legend: {
        units: "ft",
      },
    },
  },
  lastWaterLevel: {
    id: "lastWaterLevel",
    layerId: WELLS_SYMBOL_LAYER_ID,
    layerFieldName: "last_wl_water_elevation",
    name: "Last Water Level",
    layout: {
      "icon-image": "circle",
      "icon-size": COMMON_ICON_SIZE,
    },
    paint: {
      "icon-color": [
        "interpolate-hcl",
        ["linear"],
        ["coalesce", ["get", "last_wl_water_elevation"], Infinity],
        0,
        "#0d47a1", // Very Low (Dark Blue)
        500,
        "#1565c0", // Low (Medium Dark Blue)
        700,
        "#1e88e5", // Moderate Low (Bright Blue)
        850,
        "#42a5f5", // Moderate (Sky Blue)
        950,
        "#90caf9", // Moderate High (Light Blue)
        1050,
        "#e3f2fd", // High (Pale Blue)
        Infinity,
        CIRCLE_FALLBACK_COLOR,
      ],
    },
    lreProperties: {
      borderColor: "#000",
      legend: {
        units: "ft",
      },
    },
  },

  // wellTypes: {
  //   id: "wellTypes",
  //   defaultLoad: true,
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "well_type_desc",
  //   name: "Well Type",
  //   paint: {
  //     "circle-color": "#1E90FF",
  //   },
  // },
  // wellUses: {
  //   id: "wellUses",
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "use_group_name",
  //   name: "Well Use",
  //   paint: {
  //     "circle-color": "#1E90FF",
  //   },
  // },
  // aquiferGroups: {
  //   id: "aquiferGroups",
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "aquifer_group_name",
  //   name: "Aquifer",
  //   paint: {
  //     "circle-color": "#1E90FF",
  //   },
  // },
  // wellElevation: {
  //   id: "wellElevation",
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "elevation_ftabmsl",
  //   name: "Well Elevation",
  //   paint: {
  //     "circle-color": [
  //       "interpolate-hcl",
  //       ["linear"],
  //       ["coalesce", ["get", "elevation_ftabmsl"], Infinity],
  //       0,
  //       "#0d47a1", // Very Low (Dark Blue)
  //       550,
  //       "#1565c0", // Low (Medium Dark Blue)
  //       750,
  //       "#1e88e5", // Moderate Low (Bright Blue)
  //       950,
  //       "#42a5f5", // Moderate (Sky Blue)
  //       1050,
  //       "#90caf9", // Moderate High (Light Blue)
  //       1200,
  //       "#e3f2fd", // High (Pale Blue)
  //       Infinity,
  //       CIRCLE_FALLBACK_COLOR, // N/A or Null (Gray)
  //     ],
  //   },
  // },
  // wellDepth: {
  //   id: "wellDepth",
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "well_depth_ft",
  //   name: "Well Depth",
  //   paint: {
  //     "circle-color": [
  //       "interpolate-hcl",
  //       ["linear"],
  //       ["coalesce", ["get", "well_depth_ft"], Infinity],
  //       0,
  //       "#0d47a1", // Very Shallow (Dark Blue)
  //       300,
  //       "#1565c0", // Shallow (Medium Dark Blue)
  //       1000,
  //       "#1e88e5", // Moderate (Bright Blue)
  //       2000,
  //       "#42a5f5", // Deep (Sky Blue)
  //       3500,
  //       "#90caf9", // Very Deep (Light Blue)
  //       6000,
  //       "#e3f2fd", // Extreme Depth (Pale Blue)
  //       Infinity,
  //       CIRCLE_FALLBACK_COLOR, // N/A or Null (Gray)
  //     ],
  //   },
  // },
  // lastWaterLevel: {
  //   id: "lastWaterLevel",
  //   layerId: WELLS_SYMBOL_LAYER_ID,
  //   layerFieldName: "last_wl_water_elevation",
  //   name: "Last Water Level",
  //   paint: {
  //     "circle-color": [
  //       "interpolate-hcl",
  //       ["linear"],
  //       ["coalesce", ["get", "last_wl_water_elevation"], Infinity],
  //       0,
  //       "#0d47a1", // Very Low (Dark Blue)
  //       500,
  //       "#1565c0", // Low (Medium Dark Blue)
  //       700,
  //       "#1e88e5", // Moderate Low (Bright Blue)
  //       850,
  //       "#42a5f5", // Moderate (Sky Blue)
  //       950,
  //       "#90caf9", // Moderate High (Light Blue)
  //       1050,
  //       "#e3f2fd", // High (Pale Blue)
  //       Infinity,
  //       CIRCLE_FALLBACK_COLOR, // N/A or Null (Gray)
  //     ],
  //   },
  // },
};

export const SEARCH_CONFIG = {
  "groundwater-database": {
    id: "groundwater-database",
    label: "Groundwater Database (GWDB) Wells",
    key: "state_well_num",
    searchableFields: [
      "well_report_tracking_num",
      "state_well_num",
      "owner_name",
      "driller_name",
      "tx_grid_num",
    ],
    searchPlaceholder: "Search Groundwater Wells",
    displayFields: [
      { field: "state_well_num", label: "State Well #" },
      { field: "well_report_tracking_num", label: "Well Report #" },
      { field: "owner_name", label: "Owner Name" },
      { field: "driller_name", label: "Driller Name" },
      { field: "tx_grid_num", label: "TX Grid #" },
      { field: "county_name", label: "County" },
      { field: "gcd_name", label: "GCD" },
      { field: "well_type_desc", label: "Well Type" },
      { field: "primary_use_name", label: "Well Use" },
      { field: "aquifer_name", label: "Aquifer" },
    ],
  },
  "submitted-driller-reports": {
    id: "submitted-driller-reports",
    label: "Submitted Driller Reports (SDR)",
    key: "well_report_tracking_num",
    searchableFields: [
      "well_report_tracking_num",
      "plugging_report_tracking_num",
      "owner_name",
      "owner_well_number",
      "company_name",
      "license_number",
      "tx_grid_num",
    ],
    searchPlaceholder: "Search Driller Reports",
    displayFields: [
      { field: "well_report_tracking_num", label: "SDR Tracking #" },
      { field: "plugging_report_tracking_num", label: "Plugging Report #" },
      { field: "owner_name", label: "Owner Name" },
      { field: "owner_well_number", label: "Owner Well #" },
      { field: "company_name", label: "Company Name" },
      { field: "license_number", label: "License Number" },
      { field: "tx_grid_num", label: "TX Grid #" },
      { field: "county_name", label: "County" },
      { field: "gcd_name", label: "GCD" },
      { field: "primary_use_name", label: "Well Use" },
    ],
  },
  "plugging-reports": {
    id: "plugging-reports",
    label: "Plugging Reports",
    key: "plugging_report_tracking_num",
    searchableFields: ["plugging_report_tracking_num", "owner_name"],
    searchPlaceholder: "Search Plugging Reports",
    displayFields: [
      { field: "plugging_report_tracking_num", label: "Plugging Report #" },
      { field: "county_name", label: "County" },
      { field: "owner_name", label: "Owner Name" },
      { field: "well_city", label: "City" },
      { field: "well_type_desc", label: "Well Type" },
    ],
  },
  "bracs-database": {
    id: "bracs-database",
    label: "BRACS Database",
    key: "bracs_id",
    searchableFields: [
      "bracs_id",
      "owner_name",
      "state_well_number",
      "well_number",
    ],
    searchPlaceholder: "Search BRACS Database",
    displayFields: [
      { field: "bracs_id", label: "BRACS ID" },
      { field: "owner_name", label: "Owner Name" },
      { field: "state_well_number", label: "State Well #" },
      { field: "well_number", label: "Well #" },
      { field: "county_name", label: "County" },
      { field: "well_type_desc", label: "Well Type" },
      { field: "source", label: "Source" },
      { field: "well_use_name", label: "Well Use" },
    ],
  },
};

// This is the order in which the layer accordions will be displayed in the legend, if not defined here, the remaining items will be order alphabetically
export const ACCORDION_GROUPS_ORDER = [
  "Well Layers",
  "Administrative & Planning Boundaries",
  "Hydrology",
];

export const ICON_IMAGES = [
  {
    url: "/static/img/mapbox/triangle.png",
    id: "triangle",
  },
  {
    url: "/static/img/mapbox/square.png",
    id: "square",
  },
  {
    url: "/static/img/mapbox/circle.png",
    id: "circle",
  },
  {
    url: "/static/img/mapbox/star.png",
    id: "star",
  },
];
