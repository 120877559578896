import React, { useMemo, useState, memo, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Divider,
  Pagination,
  Link,
  Box,
  IconButton,
  Avatar,
} from "@mui/material";
import { titleize } from "inflected";
import { format as formatDate, isValid as isValidDate } from "date-fns";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";

// Styled components
const PopupWrap = styled("div")(({ height, width }) => ({
  height,
  overflowY: "scroll",
  maxWidth: width,
}));

const PopupTable = styled("table")({
  borderRadius: "5px",
  borderCollapse: "collapse",
  border: "1px solid #ccc",
  width: "100%",
});

const PopupRow = styled("tr")({
  borderRadius: "5px",
  "&:nth-of-type(even)": {
    backgroundColor: "#eee",
  },
});

const PopupCell = styled("td")({
  padding: "3px 6px",
  margin: 0,
});

const HeaderRow = styled("tr")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: "bold",
  textAlign: "center",
  "& td": {
    padding: "5px",
  },
}));

const ToggleButtonsContainer = styled(Box)({
  position: "absolute",
  top: "0",
  right: "20px",
  display: "flex",
});

const SizeToggleButton = ({ size, currentSize, onClick, label }) => {
  const isActive = size === currentSize;

  return (
    <IconButton
      size="small"
      color={isActive ? "primary" : "default"}
      onClick={() => onClick(size)}
      aria-label={`${label} size`}
    >
      <Avatar
        sx={{
          fontSize: "14px",
          width: 20,
          height: 20,
          backgroundColor: isActive ? "primary.main" : "grey.300",
        }}
      >
        {label}
      </Avatar>
    </IconButton>
  );
};

const getUniqueFeatures = (array, comparatorProperty1, comparatorProperty2) => {
  const existingFeatureKeys = new Set();
  return array.filter((el) => {
    const key = `${el[comparatorProperty1]}-${el.layer[comparatorProperty2]}`;
    if (existingFeatureKeys.has(key)) return false;
    existingFeatureKeys.add(key);
    return true;
  });
};

const applyFormatting = (value, formatOptions) => {
  if (!formatOptions) return value;

  let formattedValue = value;

  // Decimal precision
  if (formatOptions.decimalPrecision !== undefined && !isNaN(value)) {
    const precision = parseInt(formatOptions.decimalPrecision, 10);
    if (!isNaN(precision)) {
      formattedValue = parseFloat(formattedValue).toFixed(precision);
    }
  }

  // Convert to string for further manipulation
  formattedValue = formattedValue.toString();

  // Uppercase / Lowercase
  if (formatOptions.uppercase) formattedValue = formattedValue.toUpperCase();
  if (formatOptions.lowercase) formattedValue = formattedValue.toLowerCase();

  // Prefix / Suffix
  const parts = [];
  if (formatOptions.prefix) parts.push(formatOptions.prefix);
  parts.push(formattedValue);
  if (formatOptions.suffix) parts.push(formatOptions.suffix);
  formattedValue = parts.join("");

  // Date formatting
  if (formatOptions.dateFormat && isValidDate(new Date(value))) {
    formattedValue = formatDate(new Date(value), formatOptions.dateFormat);
  }

  return formattedValue;
};

const processTemplateString = (template, properties) => {
  // Split the template into static text and placeholders
  const parts = template.split(/({.*?})/g);

  let result = parts.map((part) => {
    // If it's a placeholder (e.g., {key}), process it
    if (part.startsWith("{") && part.endsWith("}")) {
      const key = part.slice(1, -1);
      const value = properties[key];

      if (value === undefined || value === null) return ""; // Return empty for undefined or null
      if (typeof value === "number" || typeof value === "boolean") return value; // Preserve original type
      return value.toString(); // Convert others to string
    }

    // Return static text as-is
    return part;
  });

  // If the template contains only a single placeholder, return its processed value directly
  if (parts.length === 3 && parts[0] === "" && parts[2] === "") {
    return result[1];
  }

  // Join parts back together for templates with mixed text and placeholders
  return result.join("");
};

const BUTTON_SIZE_CONFIG = {
  small: { height: "200px", width: "300px" },
  medium: { height: "300px", width: "500px" },
  large: { height: "600px", width: "900px" },
};

// Main Popup component
const Popup = ({ features, layers, currentUser, sizeRef }) => {
  const { isSm } = useBreakpoints();
  const [size, setSize] = useState(
    sizeRef?.current ? sizeRef.current : isSm ? "small" : "medium"
  );
  const [page, setPage] = useState(1);

  const prevBreakpoint = useRef(isSm);

  // Adjust size dynamically based on breakpoints
  useEffect(() => {
    if (prevBreakpoint.current === isSm) return;

    if (isSm && isSm !== prevBreakpoint.current) {
      setSize((prevSize) => (prevSize !== "small" ? "small" : prevSize));
    } else if (isSm === false && isSm !== prevBreakpoint.current) {
      setSize((prevSize) => (prevSize !== "medium" ? "medium" : prevSize));
    }
    prevBreakpoint.current = isSm;
  }, [isSm, setSize]);

  const handleSizeChange = (newSize) => {
    setSize(newSize); // Update state
    sizeRef.current = newSize; // Sync with ref
  };

  const uniqueFeatures = useMemo(
    () => getUniqueFeatures(features, "id", "id"),
    [features]
  );

  const feature = uniqueFeatures[page - 1] || null;

  const popupConfig = useMemo(() => {
    if (!feature) return {};
    const layer = layers.find((l) => l?.id === feature?.layer?.id);
    return layer?.lreProperties?.popup || {};
  }, [feature, layers]);

  const {
    excludeFields = [],
    excludePatterns = [],
    customLabels = {},
    sortOrder = [],
    reverseSortOrder = [],
    excludeAllExceptCustom = false,
    customFields = [],
    headers = [],
  } = popupConfig;

  // Only display admin fields if the user is admin or dev
  const adminOnlyFields = useMemo(() => {
    return currentUser?.isDeveloper || currentUser?.isAdmin
      ? []
      : popupConfig.adminOnlyFields ?? [];
  }, [currentUser, popupConfig]);

  // Build the popup title
  const titleField = useMemo(() => {
    if (!feature) return null;
    const titleValue = feature?.properties?.[popupConfig.titleField] ?? null;

    return (
      <>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {titleize(feature?.layer?.source)}
        </Typography>
        {titleValue && (
          <>
            <Divider sx={{ my: 1, mx: 2 }} />
            <Typography sx={{ mb: 2 }} variant="h6">
              {titleValue}
            </Typography>
          </>
        )}
      </>
    );
  }, [feature, popupConfig]);

  // Process & filter fields for popup
  const popupData = useMemo(() => {
    if (!feature) return [];

    const { properties } = feature;
    const fieldsToDisplay = [];

    // 1. Gather any non-custom fields (if excludeAllExceptCustom = false)
    Object.entries(properties).forEach(([key, value]) => {
      const matchesPattern = excludePatterns.some((pattern) =>
        key.includes(pattern)
      );
      const shouldExclude =
        excludeFields.includes(key) ||
        matchesPattern ||
        adminOnlyFields.includes(key) ||
        isNullOrUndef(value);

      const displayLabel = customLabels[key] || titleize(key);

      const fieldContent = { value, type: undefined };

      if (excludeAllExceptCustom) {
        if (
          customLabels[key] ||
          sortOrder.includes(key) ||
          reverseSortOrder.includes(key)
        ) {
          fieldsToDisplay.push([displayLabel, fieldContent]);
        }
      } else if (!shouldExclude) {
        fieldsToDisplay.push([displayLabel, fieldContent]);
      }
    });

    // 2. Process customFields
    customFields.forEach((field) => {
      const { label, value: template, displayText, type, format } = field;

      // Process the main value
      const processedValue = processTemplateString(template, properties);

      // Skip if processedValue is null/undefined/empty
      if (isNullOrUndef(processedValue) || processedValue === "") return;

      // Process displayText, if provided
      let processedDisplayText = displayText
        ? processTemplateString(displayText, properties)
        : null;

      // Skip if displayText is given but resolves to null/undefined/empty
      if (
        displayText &&
        (isNullOrUndef(processedDisplayText) || processedDisplayText === "")
      ) {
        return;
      }

      // Format them
      const formattedValue = applyFormatting(processedValue, format);
      processedDisplayText = processedDisplayText
        ? applyFormatting(processedDisplayText, format)
        : formattedValue;

      fieldsToDisplay.push([
        label,
        {
          value: formattedValue,
          displayText: processedDisplayText,
          type,
        },
      ]);
    });

    // 3. Sort fields based on sortOrder & reverseSortOrder
    const sortedFields = fieldsToDisplay.sort((a, b) => {
      const [labelA] = a;
      const [labelB] = b;

      const indexA = sortOrder.indexOf(labelA);
      const indexB = sortOrder.indexOf(labelB);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB;
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      const reverseIndexA = reverseSortOrder.indexOf(labelA);
      const reverseIndexB = reverseSortOrder.indexOf(labelB);

      if (reverseIndexA !== -1 && reverseIndexB !== -1)
        return reverseIndexB - reverseIndexA;
      if (reverseIndexA !== -1) return 1;
      if (reverseIndexB !== -1) return -1;

      return 0;
    });

    return sortedFields;
  }, [
    feature,
    excludeFields,
    excludePatterns,
    customLabels,
    sortOrder,
    reverseSortOrder,
    excludeAllExceptCustom,
    adminOnlyFields,
    customFields,
  ]);

  const handlePageChange = (e, p) => setPage(p);

  if (!feature) return null;

  return (
    <>
      <PopupWrap
        height={BUTTON_SIZE_CONFIG[size].height}
        width={BUTTON_SIZE_CONFIG[size].width}
      >
        <ToggleButtonsContainer>
          <SizeToggleButton
            size="small"
            currentSize={size}
            onClick={() => handleSizeChange("small")}
            label="S"
          />
          <SizeToggleButton
            size="medium"
            currentSize={size}
            onClick={() => handleSizeChange("medium")}
            label="M"
          />
          <SizeToggleButton
            size="large"
            currentSize={size}
            onClick={() => handleSizeChange("large")}
            label="L"
          />
        </ToggleButtonsContainer>
        {titleField}
        <PopupTable>
          <tbody>
            {popupData.map(([label, { type, value, displayText }]) => {
              const headerConfig = headers.find((h) => h.propertyKey === label);
              return (
                <React.Fragment key={label}>
                  {headerConfig && (
                    <HeaderRow>
                      <td colSpan={2}>{headerConfig.headerTitle}</td>
                    </HeaderRow>
                  )}
                  <PopupRow>
                    <PopupCell>
                      <strong>{label}</strong>
                    </PopupCell>
                    <PopupCell>
                      {type === "url" ? (
                        <Link href={value} target="_blank" rel="noopener">
                          {displayText}
                        </Link>
                      ) : (
                        // e.g. if it's text or something else
                        formatBooleanTrueFalse(value)
                      )}
                    </PopupCell>
                  </PopupRow>
                </React.Fragment>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
        count={uniqueFeatures.length}
        size={size}
        siblingCount={size === "small" ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default memo(Popup);
