import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mapboxgl from "mapbox-gl";
import debounce from "lodash.debounce";
import { createRoot } from "react-dom/client";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import RulerControl from "@mapbox-controls/ruler";
import "@mapbox-controls/ruler/src/index.css";
import { ThemeProvider } from "@mui/material/styles";

import Popup from "../../Popup";
import ResetZoomControl from "../../controls/ResetZoomControl";
import useLayers from "../useLayers";
import { useApp } from "../../../../AppProvider";
import createTheme from "../../../../theme";
import { coordinatesGeocoder, MapLogger } from "./mapUtils";
import {
  BASEMAP_STYLES,
  ICON_IMAGES,
  WELLS_LABELS_LAYER_ID,
  WELLS_SYMBOL_LAYER_ID,
} from "../../constants";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const mapLogger = new MapLogger({
  enabled: process.env.NODE_ENV === "development",
  prefix: "Public Map",
});

const popupTheme = createTheme();

/**
 * ---------------------------------------------------------------------------------------
 * UTILITY FUNCTIONS
 * ---------------------------------------------------------------------------------------
 */
/** Asynchronous image loading and adding to map */
async function loadImages(map, images) {
  await Promise.all(
    images.map(
      (img) =>
        new Promise((resolve, reject) => {
          if (map.hasImage(img.id)) {
            resolve();
            return;
          }
          map.loadImage(img.url, (error, image) => {
            if (error) return reject(error);
            if (!map.hasImage(img.id)) {
              map.addImage(img.id, image, { sdf: true });
            }
            resolve();
          });
        })
    )
  );
  mapLogger.log("Images loaded");
}

/** Sort layers by layerOrder so they’re drawn in correct z-index order. */
function sortLayers(layers) {
  return [...layers].sort((a, b) => {
    const orderA = a?.lreProperties?.layerOrder ?? 0;
    const orderB = b?.lreProperties?.layerOrder ?? 0;
    return orderA - orderB;
  });
}

/** Add sources to map if they don't exist. */
function addSources(map, sourcesArray) {
  sourcesArray.forEach(({ id, ...rest }) => {
    if (!map.getSource(id)) {
      map.addSource(id, rest);
      mapLogger.log(`Source loading: ${id}`);
    }
  });
  mapLogger.log("Sources added to map");
}

/** Add layers to map, applying optional filters if provided. */
function addLayers({
  map,
  layers,
  filters,
  updateLayerFilters,
  wellLayerIds = [
    WELLS_SYMBOL_LAYER_ID,
    WELLS_LABELS_LAYER_ID,
    `${WELLS_SYMBOL_LAYER_ID}-border`,
  ],
}) {
  const sortedLayers = sortLayers(layers);

  sortedLayers.forEach((layer) => {
    if (!map.getLayer(layer.id)) {
      map.addLayer(layer);
      mapLogger.log(`Layer loading: ${layer.id}`);

      // Apply filters to well layers
      if (wellLayerIds.includes(layer.id)) {
        updateLayerFilters(filters, [layer.id]);
      }
    }
  });

  // Ensure well-border is below well-symbol
  if (
    map.getLayer(`${WELLS_SYMBOL_LAYER_ID}-border`) &&
    map.getLayer(WELLS_SYMBOL_LAYER_ID)
  ) {
    map.moveLayer(`${WELLS_SYMBOL_LAYER_ID}-border`, WELLS_SYMBOL_LAYER_ID);
    mapLogger.log(
      `Moved ${WELLS_SYMBOL_LAYER_ID}-border layer below: ${WELLS_SYMBOL_LAYER_ID}`
    );
  }

  mapLogger.log("Layers added to map");
}

/**
 * ---------------------------------------------------------------------------------------
 * REACT HOOK: useMap
 * ---------------------------------------------------------------------------------------
 */
const useMap = (ref, mapConfig, sources) => {
  /**
   * ------------------------------------------------------------------------
   * HOOKS, STATE, REFS
   * ------------------------------------------------------------------------
   */
  const { currentUser } = useApp();
  const { layers, setLayers } = useLayers();

  const [map, setMap] = useState(null);
  const [activeBasemap, setActiveBasemap] = useState(
    mapConfig?.style || BASEMAP_STYLES[0].style
  );
  const [isMapReady, setIsMapReady] = useState(false);

  const hasLoadedDataRef = useRef(false);
  const controlsAddedRef = useRef(false);
  const isPointerRef = useRef(false);
  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "100%",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const popupSizeRef = useRef(null);

  /**
   * ------------------------------------------------------------------------
   * MAP CALLBACKS
   * ------------------------------------------------------------------------
   */
  const updateLayerFilters = useCallback(
    (
      filterValues,
      layerIds = [
        WELLS_SYMBOL_LAYER_ID,
        WELLS_LABELS_LAYER_ID,
        `${WELLS_SYMBOL_LAYER_ID}-border`,
      ]
    ) => {
      if (!map) return;

      const newFilterExpression = ["all"];

      Object.values(filterValues).forEach((filter) => {
        if (filter.type === "multi-select") {
          // Filter by matching any selected values or empty
          newFilterExpression.push([
            "match",
            ["coalesce", ["get", filter.layerFieldName], ""],
            filter.value.length ? [...filter.value, ""] : [""],
            true,
            false,
          ]);
        } else if (filter.type === "boolean" && filter.value === true) {
          newFilterExpression.push([
            "==",
            ["get", filter.layerFieldName],
            true,
          ]);
        }
      });

      layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.setFilter(layerId, newFilterExpression);
          mapLogger.log(`Filter updated for ${layerId}`);
        }
      });
    },
    [map]
  );

  const updateLayerStyles = useCallback(
    (layerUpdate) => {
      if (!map) return;

      const isValidColor = (color) => {
        const s = new Option().style;
        s.color = color;
        return s.color !== "";
      };

      const removeBorderLayer = (layerId) => {
        const borderLayerId = `${layerId}-border`;
        if (map.getLayer(borderLayerId)) {
          map.removeLayer(borderLayerId);
          mapLogger.log(`Border layer removed: ${borderLayerId}`);
        }
        setLayers((prev) => prev.filter((l) => l.id !== borderLayerId));
      };

      const addBorderLayer = (currentLayer) => {
        const layerId = currentLayer.layerId;
        const borderLayerId = `${layerId}-border`;

        // Remove existing border layer
        removeBorderLayer(layerId);

        // Convert icon-size to circle-radius
        const iconSize = map.getLayoutProperty(layerId, "icon-size") || 1;
        const borderRadiusExpression =
          Array.isArray(iconSize) && iconSize[0] === "interpolate"
            ? [
                "interpolate",
                iconSize[1],
                iconSize[2],
                ...iconSize
                  .slice(3)
                  .map((val, i) => (i % 2 === 0 ? val : (val * 24) / 2 + 1)),
              ]
            : iconSize;

        const borderColor = isValidColor(
          currentLayer.lreProperties?.borderColor
        )
          ? currentLayer.lreProperties.borderColor
          : "#000";

        const parentFilter = map.getFilter(layerId) || ["all"];

        if (!map.getLayer(borderLayerId)) {
          map.addLayer({
            id: borderLayerId,
            type: "circle",
            source: map.getLayer(layerId).source,
            paint: {
              "circle-color": borderColor,
              "circle-radius": borderRadiusExpression,
            },
            filter: parentFilter,
          });
          map.moveLayer(borderLayerId, layerId);
          mapLogger.log(`Border layer added: ${borderLayerId}`);

          // Add to local state
          setLayers((prev) => [
            ...prev,
            {
              id: borderLayerId,
              type: "circle",
              source: map.getLayer(layerId).source,
              paint: {
                "circle-color": borderColor,
                "circle-radius": borderRadiusExpression,
              },
              filter: parentFilter,
              lreProperties: {
                legend: { labelGroup: "groundwater-database" },
              },
              layout: { visibility: "visible" },
            },
          ]);
        }
      };

      const applyPaintProperties = (paint) => {
        if (!paint) return;
        Object.entries(paint).forEach(([ruleName, ruleValue]) => {
          map.setPaintProperty(layerUpdate.layerId, ruleName, ruleValue);
        });
      };

      // Update matching layer in local state
      const updateLayerInState = (updater) => {
        setLayers((prevLayers) =>
          prevLayers.map((existingLayer) =>
            existingLayer.id === layerUpdate.layerId
              ? updater(existingLayer)
              : existingLayer
          )
        );
      };

      const resetLegendSymbols = () => {
        updateLayerInState((layerData) => ({
          ...layerData,
          lreProperties: {
            ...layerData.lreProperties,
            legend: { ...layerData.lreProperties?.legend, symbols: undefined },
          },
        }));
      };

      const resetPaintProperties = () => {
        updateLayerInState((layerData) => ({
          ...layerData,
          layout: {
            ...layerData.layout,
            "icon-size": 1,
          },
          paint: {},
        }));
      };

      const finalizeLayerProperties = () => {
        updateLayerInState((layerData) => ({
          ...layerData,
          layout: {
            ...layerData.layout,
            ...layerUpdate.layout,
          },
          paint: {
            ...layerData.paint,
            ...layerUpdate.paint,
          },
          lreProperties: {
            ...layerData.lreProperties,
            legend: {
              ...layerData?.lreProperties?.legend,
              ...(layerUpdate?.lreProperties?.legend?.symbols
                ? { symbols: layerUpdate.lreProperties.legend.symbols }
                : {}),
              ...(layerUpdate?.lreProperties?.legend?.units
                ? { units: layerUpdate.lreProperties.legend.units }
                : { units: undefined }),
            },
            borderColor: undefined,
          },
        }));
      };

      // If icon-image changes
      if (layerUpdate?.layout?.["icon-image"]) {
        map.setLayoutProperty(
          layerUpdate.layerId,
          "icon-image",
          layerUpdate.layout["icon-image"]
        );
        map.setLayoutProperty(
          layerUpdate.layerId,
          "icon-size",
          layerUpdate.layout["icon-size"]
        );

        const isCircle = layerUpdate.layout["icon-image"] === "circle";
        if (isCircle) {
          if (layerUpdate.lreProperties?.borderColor) {
            addBorderLayer(layerUpdate); // Add the border if valid
          } else {
            removeBorderLayer(layerUpdate.layerId); // Explicitly remove the border if missing
          }
          applyPaintProperties(layerUpdate.paint);
          resetLegendSymbols();
        } else {
          removeBorderLayer(layerUpdate.layerId); // Always remove the border if not a circle
          resetPaintProperties();
        }
        finalizeLayerProperties();
        mapLogger.log(`Layout icon-image updated on ${layerUpdate.layerId}`);
      } else {
        applyPaintProperties(layerUpdate.paint);
        finalizeLayerProperties();
        mapLogger.log(`Paint styles updated on ${layerUpdate.layerId}`);
      }
    },
    [map, setLayers]
  );

  const updateLayerVisibility = useCallback(
    ({ id, visible }) => {
      if (!map) return;

      const visibility = visible ? "visible" : "none";

      // Find grouped layers (same labelGroup) or the layer itself
      const groupedLayerIds = layers
        .filter(
          (layer) =>
            layer.lreProperties?.legend?.labelGroup === id || layer.id === id
        )
        .map(({ id }) => id);

      const updatedLayers = layers.map((layer) => {
        if (groupedLayerIds.includes(layer.id) && map.getLayer(layer.id)) {
          map.setLayoutProperty(layer.id, "visibility", visibility);
          return {
            ...layer,
            layout: {
              ...layer.layout,
              visibility,
            },
          };
        }
        return layer;
      });

      setLayers(updatedLayers);
      mapLogger.log(`Visibility set to '${visibility}' for '${id}'`);
    },
    [map, layers, setLayers]
  );

  const updateLayerOpacity = useCallback(
    ({ id, opacity }) => {
      if (!map) return;

      if (map.getLayer(id)) {
        map.setPaintProperty(id, "fill-opacity", opacity);
        mapLogger.log(`Opacity set to '${opacity}' for layer: '${id}'`);
      }

      setLayers((prevLayers) =>
        prevLayers.map((layer) =>
          layer.id === id
            ? {
                ...layer,
                paint: {
                  ...layer.paint,
                  "fill-opacity": opacity,
                },
              }
            : layer
        )
      );
    },
    [map, setLayers]
  );

  /**
   * ------------------------------------------------------------------------
   * MAP INITIALIZATION (Creates map instance on mount)
   * ------------------------------------------------------------------------
   */
  const initializeMap = useCallback(() => {
    if (ref.current && !map) {
      const mapInstance = new mapboxgl.Map({
        container: ref.current,
        ...mapConfig,
      });

      mapLogger.log("Map initialized");
      setMap(mapInstance);

      return () => {
        mapInstance.remove();
      };
    }
  }, [ref, map, mapConfig]);

  /**
   * ------------------------------------------------------------------------
   * ADD MAP CONTROLS (Navigation, Fullscreen, Zoom, etc.)
   * ------------------------------------------------------------------------
   */
  const addMapControls = useCallback(() => {
    if (!map || controlsAddedRef.current) return;

    const controls = [
      { control: new mapboxgl.NavigationControl(), position: "top-left" },
      { control: new mapboxgl.FullscreenControl(), position: "top-left" },
      {
        control: new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        position: "top-left",
      },
      { control: new ResetZoomControl(map), position: "top-left" },
      {
        control: new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder,
          container: "geocoder-container",
          zoom: 16,
          mapboxgl,
          reverseGeocode: true,
          placeholder: "Address/Coords",
        }),
        position: "top-right",
      },
      {
        control: new mapboxgl.ScaleControl({
          unit: "imperial",
          maxWidth: 250,
        }),
        position: "bottom-left",
      },
      {
        control: new RulerControl({
          units: "yards",
          labelFormat: (n) => `${n.toFixed(2)} yd`,
        }),
        position: "bottom-left",
      },
    ];

    controls.forEach(({ control, position }) => {
      map.addControl(control, position);
    });

    mapLogger.log("Map controls added");
    controlsAddedRef.current = true;

    return () => {
      controls.forEach(({ control }) => map.removeControl(control));
    };
  }, [map]);

  /**
   * ------------------------------------------------------------------------
   * MAP EVENTS: (Hover, Click, Zoom, etc.)
   * ------------------------------------------------------------------------
   */
  // Layer IDs that require cursor pointer on hover
  const pointerLayerIds = useMemo(
    () =>
      layers
        .filter(
          (layer) =>
            !layer.lreProperties?.disableCursorPointer &&
            !layer.lreProperties?.popup?.disabled
        )
        .map((layer) => layer.id),
    [layers]
  );

  // Layer IDs that can have popups
  const popupLayerIds = useMemo(
    () =>
      layers
        .filter((layer) => !layer.lreProperties?.popup?.disabled)
        .map((layer) => layer.id),
    [layers]
  );

  const addMapEvents = useCallback(() => {
    if (!map || layers.length === 0) return;

    // Cursor change on layer hover
    const handleMouseEnter = () => {
      if (!isPointerRef.current) {
        map.getCanvas().style.cursor = "pointer";
        isPointerRef.current = true;
      }
    };

    const handleMouseLeave = () => {
      map.getCanvas().style.cursor = "";
      isPointerRef.current = false;
    };

    // Popups on layer click
    const handleMapClick = (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: popupLayerIds,
      });
      if (!features.length) return;

      // Only target features belonging to popup-able layers
      const myFeatures = features.filter((f) =>
        popupLayerIds.includes(f.layer.id)
      );
      if (!myFeatures.length) return;

      const popupNode = document.createElement("div");
      const root = createRoot(popupNode);
      root.render(
        <ThemeProvider theme={popupTheme}>
          <Popup
            layers={layers}
            features={myFeatures}
            currentUser={currentUser}
            sizeRef={popupSizeRef}
          />
        </ThemeProvider>
      );

      popUpRef.current
        .setLngLat(e.lngLat)
        .setDOMContent(popupNode)
        .addTo(map)
        .getElement()
        .querySelector(".mapboxgl-popup-close-button")
        .removeAttribute("aria-hidden");
    };

    // Layer visibility based on zoom threshold
    let previousZoomLevel = map.getZoom();
    const handleZoom = () => {
      const zoomLevel = map.getZoom();
      let hasChanges = false;

      setLayers((prevLayers) => {
        const updatedLayers = prevLayers.map((layer) => {
          const { zoomThreshold } = layer.lreProperties || {};
          if (!zoomThreshold) return layer;

          const isVisible =
            map.getLayoutProperty(layer.id, "visibility") === "visible";
          let visibilityChanged = false;

          // Cross threshold upwards => show layer
          if (previousZoomLevel < zoomThreshold && zoomLevel >= zoomThreshold) {
            if (!isVisible) {
              map.setLayoutProperty(layer.id, "visibility", "visible");
              visibilityChanged = true;
            }
          }

          // Cross threshold downwards => hide layer
          else if (
            previousZoomLevel >= zoomThreshold &&
            zoomLevel < zoomThreshold
          ) {
            if (isVisible) {
              map.setLayoutProperty(layer.id, "visibility", "none");
              visibilityChanged = true;
            }
          }

          if (visibilityChanged) {
            hasChanges = true;
            return {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: zoomLevel >= zoomThreshold ? "visible" : "none",
              },
            };
          }
          return layer;
        });

        previousZoomLevel = zoomLevel;
        return hasChanges ? updatedLayers : prevLayers;
      });
    };

    map.on("click", handleMapClick);
    map.on("zoom", handleZoom);
    pointerLayerIds.forEach((layerId) => {
      map.on("mousemove", layerId, handleMouseEnter);
      map.on("mouseleave", layerId, handleMouseLeave);
    });

    mapLogger.log("Map events added");

    return () => {
      layers.forEach((layerId) => {
        map.off("mousemove", layerId, handleMouseEnter);
        map.off("mouseleave", layerId, handleMouseLeave);
      });
      map.getCanvas().style.cursor = "";
      map.off("click", handleMapClick);
      map.off("zoom", handleZoom);
    };
  }, [map, pointerLayerIds, popupLayerIds, layers, currentUser, setLayers]);

  /**
   * ------------------------------------------------------------------------
   * LOAD MAP: (Images, Sources, Layers, Controls, Events)
   * ------------------------------------------------------------------------
   */
  const loadMapData = useCallback(
    async (filters = {}) => {
      // Prevent re-loading of data
      if (hasLoadedDataRef.current) {
        mapLogger.log("loadMapData skipped: data already loaded once.");
        return;
      }
      hasLoadedDataRef.current = true;

      try {
        // 1) Load images
        await loadImages(map, ICON_IMAGES);

        // 2) Add sources
        addSources(map, sources);

        // 3) Add layers & apply filters
        addLayers({
          map,
          layers,
          filters,
          updateLayerFilters,
        });

        // 4) Wait for map to be idle to add controls and events and then mark as ready
        await new Promise((resolve) => {
          map.once("idle", () => {
            addMapControls();
            addMapEvents();
            setIsMapReady(true);
            mapLogger.log("Map is idle. Map is Ready.");
            resolve();
          });
        });
      } catch (error) {
        console.error("Error loading map data:", error);
        mapLogger.log("Error loading map data:", error);
      }
    },
    [map, layers, sources, updateLayerFilters, addMapControls, addMapEvents]
  );

  /**
   * ------------------------------------------------------------------------
   * SWITCH BASEMAP: (Update map style and reload data, sources, layers, and events)
   * ------------------------------------------------------------------------
   */
  const updateBasemap = useCallback(
    async (newBasemap, filters) => {
      if (!map) return;

      // Force a data reload on style change
      hasLoadedDataRef.current = false;

      try {
        map.setStyle(newBasemap.url);
        setActiveBasemap(newBasemap.style);

        // Wait for new style to load
        await new Promise((resolve, reject) => {
          map.once("idle", resolve);
          setTimeout(
            () => reject(new Error("Map style load timed out")),
            10000
          );
        });
        mapLogger.log(`Style loaded. Basemap switched to: ${newBasemap.style}`);

        // Reload map data after style switch
        await loadMapData(filters);
      } catch (error) {
        console.error("Error switching basemap:", error);
        mapLogger.log("Error switching basemap:", error);
      }
    },
    [map, loadMapData]
  );

  /**
   * ------------------------------------------------------------------------
   * EFFECTS (Map initialization, resizer, load data)
   * ------------------------------------------------------------------------
   */
  // Initialize map on mount
  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  // Resize map on container resize
  useEffect(() => {
    if (!map || !ref.current) return;

    const handleResize = debounce(() => {
      map.resize();
    }, 0);

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, [map, ref]);

  // Load map data once style and dependencies are ready (initial load)
  useEffect(() => {
    if (!map || !sources.length || !layers.length || hasLoadedDataRef.current)
      return;

    const handleMapIdle = () => {
      mapLogger.log("Style loaded");
      loadMapData().catch((err) => {
        console.error("Failed to load map data:", err);
      });
    };

    if (map.isStyleLoaded() && map.areTilesLoaded()) {
      handleMapIdle();
    } else {
      map.once("idle", handleMapIdle);
    }

    return () => {
      map.off("idle", handleMapIdle);
    };
  }, [map, sources, layers, loadMapData]);

  /**
   * ------------------------------------------------------------------------
   * RETURN HOOK OUTPUT
   * ------------------------------------------------------------------------
   */
  return {
    map,
    isMapReady,
    activeBasemap,
    layers,
    updateBasemap,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
  };
};

export { useMap };
