import React, { memo, useEffect, useMemo, useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Slider,
  Typography,
  Accordion,
  AccordionSummary,
  ListItemIcon,
  ButtonBase,
  Tooltip,
  FormControlLabel,
} from "@mui/material";
import {
  ExpandMore,
  ChevronRight,
  InsertLink,
  InfoOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { customSecondary } from "../../../../theme/variants";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import { ACCORDION_GROUPS_ORDER } from "../../constants";

/** ------------------------ Styled Components ------------------------ */
const SidebarSection = styled(Typography)(({ theme }) => ({
  color: customSecondary[500],
  padding: theme.spacing(1, 5, 0),
  opacity: 0.9,
  fontWeight: theme.typography.fontWeightBold,
  display: "block",
}));

const ListItem = styled(MuiListItem)({
  paddingTop: 0,
  paddingBottom: 0,
});

/** ------------------------ Helper Functions ------------------------ */

/**
 * Returns an array of legend entries, each containing a color and a text label,
 * based on the layer's color interpolation/match expression.
 */
function getLegendOptions(layer) {
  const colorProperty = `${
    layer?.type === "symbol" && layer?.paint?.["icon-color"]
      ? "icon"
      : layer?.type
  }-color`;
  const color = layer?.paint?.[colorProperty];
  const units = layer?.lreProperties?.legend?.units
    ? ` ${layer.lreProperties.legend.units}`
    : "";

  // Simple color (no expression)
  if (!Array.isArray(color)) {
    return [{ color, text: layer.name }];
  }

  const [type, ...expression] = color;

  // Handle 'interpolate' expression
  if (type.includes("interpolate")) {
    const stops = expression.slice(2);
    const infinityIndex = stops.indexOf(Infinity);
    let infinityColor = null;

    // If Infinity is used as a stop, remove that entry & store its color
    if (infinityIndex !== -1) {
      infinityColor = stops[infinityIndex + 1];
      stops.splice(infinityIndex, 2);
    }

    const legendOptions = stops.reduce((acc, stop, index) => {
      const nextValue = stops[index + 2];
      // Each pair => color at stops[index + 1], range from stop -> nextValue
      if (index % 2 === 0 && nextValue !== undefined) {
        acc.push({
          color: stops[index + 1],
          text:
            index === 0
              ? `${stop} - ${nextValue}${units}`
              : `> ${stop} - ${nextValue}${units}`,
        });
      }
      return acc;
    }, []);

    // Final range above the last stop
    if (stops.length > 1) {
      legendOptions.push({
        color: stops[stops.length - 1],
        text: `> ${stops[stops.length - 2]}${units}`,
      });
    }

    // Optional "N/A" for Infinity
    if (infinityColor) {
      legendOptions.push({
        color: infinityColor,
        text: "N/A",
      });
    }
    return legendOptions;
  }

  // Handle 'match' expression
  if (type === "match") {
    const matches = expression.slice(1, -1);
    const defaultColor = expression[expression.length - 1];

    const legendOptions = matches.reduce((acc, value, index) => {
      if (index % 2 === 0) {
        const legendText = layer?.lreProperties?.legend?.textOverrides
          ? layer.lreProperties.legend.textOverrides[value] ?? value
          : value;

        acc.push({
          color: matches[index + 1],
          text: `${legendText}${units}`,
        });
      }
      return acc;
    }, []);

    legendOptions.push({ color: defaultColor, text: "N/A" });
    return legendOptions;
  }

  // Fallback
  return [{ color, text: layer.name }];
}

/** Renders a circle symbol for legend items (fallback if no sprite image). */
function LegendSymbol({ color }) {
  return (
    <Box
      bgcolor={color}
      borderRadius="50%"
      height={12}
      width={12}
      sx={{ minWidth: "12px" }}
    />
  );
}

/** Renders either the expand or collapse icon for a layer group. */
function LayerLegendIcon({ open }) {
  return open ? <ExpandMore /> : <ChevronRight />;
}

/** Determine whether the user can see the fill opacity slider for this item. */
function shouldRenderOpacitySlider(item) {
  const sliderDisabled =
    item?.lreProperties?.legend?.fillOpacitySlider === false;
  const hasValidOpacity =
    item?.type === "fill" && typeof item.paint?.["fill-opacity"] === "number";
  return !sliderDisabled && hasValidOpacity;
}

/** Slider sub-component to handle fill opacity changes. */
function LayerSlider({ item, onOpacityChange }) {
  const layerFillOpacity = item.paint?.["fill-opacity"];
  const [sliderValue, setSliderValue] = useState(
    +(layerFillOpacity * 100).toFixed(0) ?? 0
  );

  const handleSliderChange = (_, newValue) => setSliderValue(newValue);
  const handleSliderCommit = (_, newValue) =>
    onOpacityChange({ id: item.id, opacity: newValue / 100 });

  return (
    <Box mb={-2}>
      <SidebarSection>Fill Opacity</SidebarSection>
      <Slider
        color="secondary"
        valueLabelDisplay="auto"
        value={sliderValue}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderCommit}
        aria-labelledby="fill-opacity-slider"
      />
    </Box>
  );
}

/** Returns either a sprite image (by key) or a circle color for the legend. */
function getSymbol(value, color) {
  return value ? (
    <img
      src={`https://wilco-dashboard-files.s3.us-west-1.amazonaws.com/ind-sprites/${value}.png`}
      alt=""
    />
  ) : (
    <LegendSymbol color={color} />
  );
}

/** Legend details for each layer (color stops, slider, etc.). */
function LayerLegend({ item, open, onOpacityChange }) {
  const { isSm } = useBreakpoints();
  if (!open) return null;

  const legendItems =
    item?.type === "symbol" && item?.lreProperties?.legend?.symbols?.length
      ? item.lreProperties.legend.symbols
      : getLegendOptions(item);

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="4px"
      mb={2}
      mx={isSm ? "18px" : 11}
    >
      {legendItems.map(({ color, text, display, value }) => (
        <Box
          className="print-legend"
          key={text || display}
          display="flex"
          alignItems="center"
          columnGap="8px"
        >
          {getSymbol(value, color)}
          <Typography color="textSecondary" variant="body2">
            {text || display}
          </Typography>
        </Box>
      ))}
      {shouldRenderOpacitySlider(item) && (
        <LayerSlider item={item} onOpacityChange={onOpacityChange} />
      )}
    </Box>
  );
}

/** Renders a clickable link row (external or internal) in place of the standard list item. */
function LayerLink({ item, layerVisible }) {
  const { url, newTab = true } = item?.lreProperties?.legend?.link;
  const isExternal = url.startsWith("http");

  const linkProps = isExternal
    ? {
        component: "a",
        href: url,
        target: newTab ? "_blank" : "_self",
        rel: "noopener noreferrer",
      }
    : { component: RouterLink, to: url };

  return (
    <Tooltip
      title={newTab ? "Opens in a new tab" : "Opens in the same tab"}
      arrow
    >
      <ButtonBase {...linkProps} sx={{ width: "100%" }}>
        <ListItem sx={{ cursor: "pointer" }}>
          <ListItemIcon>
            <InsertLink />
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            primaryTypographyProps={{
              color: layerVisible ? "textPrimary" : "textSecondary",
            }}
          />
        </ListItem>
      </ButtonBase>
    </Tooltip>
  );
}

/** Renders a clickable link to the data source reference in the legend. */
const Source = ({ item }) => {
  const source = item?.lreProperties?.popup?.source;

  if (!source) return null;

  return (
    <FormControlLabel
      style={{ margin: "0 7px 0 5px", cursor: "initial" }}
      aria-label="data source"
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      control={
        <Tooltip
          title={
            <div>
              <div>Data Source:</div>
              <div>
                <strong>{source.description}</strong>
              </div>
            </div>
          }
          aria-label="data source"
        >
          {source.url ? (
            <a
              href={source.url}
              rel="noreferrer noopener"
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
                display: "flex",
              }}
            >
              <InfoOutlined
                style={{
                  color: "#1976d2",
                  cursor: "pointer",
                }}
              />
            </a>
          ) : (
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              <InfoOutlined />
            </Typography>
          )}
        </Tooltip>
      }
      label=""
    />
  );
};

/** ------------------------ Main Component ------------------------ */
function LayersControl({ items, onLayerChange, onOpacityChange }) {
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  /**
   * Whenever `items` changes, ensure the expanded items & accordions
   * reflect items currently "visible".
   */
  useEffect(() => {
    if (items.length === 0) return;

    const visibleItems = items.filter(
      (item) => item.layout.visibility === "visible"
    );

    // 1) Expanded items
    setExpandedItems((prev) => {
      const newlyVisibleItemNames = visibleItems.map((itm) => itm.name);
      // Keep only items in newlyVisibleItemNames, plus any you had before that are still visible
      const merged = [...new Set([...prev, ...newlyVisibleItemNames])];
      return merged.filter((name) => newlyVisibleItemNames.includes(name));
    });

    // 2) Expanded accordions
    setExpandedAccordions((prev) => {
      const visibleGroups = [
        ...new Set(
          visibleItems
            .map((itm) => itm?.lreProperties?.legend?.accordionGroup)
            .filter(Boolean)
        ),
      ];
      // Once an accordion is expanded, we don’t collapse it automatically
      return [...new Set([...prev, ...visibleGroups])];
    });
  }, [items]);

  /**
   * Build a map of unique items (grouped by labelGroup),
   * plus a sorted list of unique accordion groups.
   */
  const { uniqueItems, uniqueAccordionGroups } = useMemo(() => {
    const itemMap = new Map();
    const accordionGroupSet = new Set();

    items.forEach((item) => {
      const groupKey = item?.lreProperties?.legend?.labelGroup || item.id;
      if (!itemMap.has(groupKey)) {
        // Default 'Other' if no group is specified
        if (!item?.lreProperties?.legend?.accordionGroup) {
          item.lreProperties.legend.accordionGroup = "Other";
        }
        itemMap.set(groupKey, item);
        accordionGroupSet.add(item.lreProperties.legend.accordionGroup);
      }
    });

    // Sort accordion groups based on a predefined order
    const sortedAccordionGroups = [...accordionGroupSet].sort((a, b) => {
      const indexA = ACCORDION_GROUPS_ORDER.indexOf(a);
      const indexB = ACCORDION_GROUPS_ORDER.indexOf(b);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB;
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      return a.localeCompare(b);
    });

    // Sort items by an 'order' property if provided
    const sortedUniqueItems = Array.from(itemMap.values()).sort((a, b) => {
      const orderA = a?.lreProperties?.legend?.order ?? 0;
      const orderB = b?.lreProperties?.legend?.order ?? 0;
      return orderA - orderB;
    });

    return {
      uniqueItems: sortedUniqueItems,
      uniqueAccordionGroups: sortedAccordionGroups,
    };
  }, [items]);

  /** Toggles a layer’s visibility (checkbox). */
  const toggleVisibility = useCallback(
    (item) => {
      onLayerChange({
        id: item?.lreProperties?.legend?.labelGroup || item.id,
        visible: item.layout.visibility === "none",
      });
    },
    [onLayerChange]
  );

  /** Expands or collapses a specific layer's legend. */
  const toggleExpandedItem = useCallback((itemName) => {
    setExpandedItems((prev) =>
      prev.includes(itemName)
        ? prev.filter((i) => i !== itemName)
        : [...prev, itemName]
    );
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <List dense>
        {!uniqueItems.length && (
          <Box textAlign="center">
            <Typography variant="body1">No layers found</Typography>
          </Box>
        )}

        {uniqueAccordionGroups.map((group) => (
          <Accordion
            key={group}
            expanded={expandedAccordions.includes(group)}
            onChange={(_, isExpanded) =>
              setExpandedAccordions((prev) =>
                isExpanded ? [...prev, group] : prev.filter((g) => g !== group)
              )
            }
          >
            <AccordionSummary
              sx={{ display: "flex", flexDirection: "row-reverse" }}
              expandIcon={<ExpandMore />}
            >
              <Typography variant="subtitle1">{group}</Typography>
            </AccordionSummary>

            {uniqueItems
              .filter(
                (itm) => itm.lreProperties?.legend?.accordionGroup === group
              )
              .map((item) => {
                const open = expandedItems.includes(item.name);
                const visible = item.layout.visibility === "visible";

                return (
                  <Box key={item.name}>
                    {item?.lreProperties?.legend?.link?.url ? (
                      <LayerLink item={item} layerVisible={visible} />
                    ) : (
                      <ListItem
                        onClick={() => {
                          toggleVisibility(item);
                          // If item is going from hidden->visible or visible->hidden,
                          // also toggle the expansion
                          if ((!visible && !open) || (visible && open)) {
                            toggleExpandedItem(item.name);
                          }
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={visible}
                          disableRipple
                        />

                        <Source item={item} />

                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            color: visible ? "textPrimary" : "textSecondary",
                          }}
                        />
                        {!item?.lreProperties?.legend?.disableCollapse && (
                          <Box mr={1}>
                            <IconButton
                              edge="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleExpandedItem(item.name);
                              }}
                            >
                              <LayerLegendIcon open={open} />
                            </IconButton>
                          </Box>
                        )}
                      </ListItem>
                    )}

                    {!item?.lreProperties?.legend?.disableCollapse && (
                      <LayerLegend
                        open={open}
                        item={item}
                        onOpacityChange={onOpacityChange}
                      />
                    )}
                  </Box>
                );
              })}
          </Accordion>
        ))}
      </List>
    </Box>
  );
}

export default memo(LayersControl);
